import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';

import { OnBoardingHeader } from 'components/OnBoardingHeader';

export class OnBoardingLayout extends Component {
  render(){
    const {
      children,
      backButton = null,
      className,
      header = {},
      style,
      title,
      zoneAfterHeader,
      zoneBeforeTitle,
      zoneFooter,
    } = this.props;

    return (
      <div className={`onboarding-layout ${className ? className : ''}`} style={style}>
        <OnBoardingHeader {...header} backButton={backButton} />
        {zoneAfterHeader}
        <Container>
          {zoneBeforeTitle}
          {title && (
            <Header as='h1'>
              {title}
            </Header>
          )}
          <section className={`content`}>
            {children}
          </section>
          {zoneFooter}
        </Container>
      </div>
    );
  }
}

import gql from "graphql-tag";

export const GET_MANAGEMENT_PORTSCANS = gql`
  query GET_MANAGEMENT_PORTSCANS(
    $page: Int,
		$itemsPerPage: Int,
		$organizationSlug: ShortString,
		$networkId: UUID,
		$deviceId: String,
		$projectId: UUID,
		$hostId: UUID,
		$orderBy: PortScanOrderBy,
		$direction: Direction,
		$search: ShortString,
		$fromDate: DateTime,
		$toDate: DateTime,
		$status: [TaskStatusEnum!],
		$hostIsUp: Boolean, 
		$hasResult: Boolean,
		$portScanIds: [UUID!],
		$askForPagination: Boolean!,
		$scanTypes: [ScanTypeEnum!]
  ){    
   	management {
	    portScans(
		    page: $page,
				itemsPerPage: $itemsPerPage,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				deviceId: $deviceId,
				projectId: $projectId,
	  		hostId: $hostId, 
				orderBy: $orderBy,
				direction: $direction,
				search: $search,
				fromDate: $fromDate,
				toDate: $toDate,
				status: $status,
				hostIsUp: $hostIsUp, 
				portScanIds: $portScanIds,
				hasResult: $hasResult
				scanTypes: $scanTypes,
	    ) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
	      results {
	        id
	        scheduledTime
	        duration
	        hostIsUp
	        rawDeviceId
	        task{
	        	errorMessage
	        }
        	network{
        		id
        		location
        	}
      		organization{
      			id
      			slug
      			name
      		}
	        fileName
	        fileSize
	        vlan {
	        	id
						vid
						name
						type
						accessPolicy
						description
	        }
	        host {
	          id
	          displayName
	        }
	        requestedBy { 
	        	firstname
	        	lastname
	        }
	        portCount
	        device {
	          id
	          isAlive
	          deviceId
	          isRebooting
	          location
	          actions {
	            action
	            enabled
	            disabledReason
	          }
	        }
	        task{
	        	id
		        startTime
		        endTime
		        status	        	
	        }
	        osDetection
	        tcp
	        udp	        
	        hostIpAddr
	        hostMacAddr
	        originalVlanName
	        result {
		        openPorts {
		          protocol
		          port
		          service
		          product
		          version
		          state
		        }
		        closedTcpPorts
						closedUdpPorts
		        osDetection {
		          name
		          accuracy
		          osFamily
		          osGen
		          cpe
		          type
		          vendor
		        }
		      }
	      }
	    }
	  }
	}
`;
export const GET_MANAGEMENT_PORTSCAN_LASTVALID = gql`
  query GET_MANAGEMENT_PORTSCAN_LASTVALID(
		$organizationSlug: ShortString,
		$networkId: UUID,
		$deviceId: String,
		$projectId: UUID,
		$hostId: UUID,
		$scanTypes: [ScanTypeEnum!]
  ){    
   	management {
	    lastPortScan_Date : portScans(
		    page: 1,
				itemsPerPage: 1,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				deviceId: $deviceId,
				projectId: $projectId,
	  		hostId: $hostId, 
				orderBy: SCHEDULED_TIME,
				direction: DESC,
				status: [COMPLETED],
				scanTypes: $scanTypes,
	    ) {
	      results {
	        id
	      }
	    }
	    portScans(
		    page: 1,
				itemsPerPage: 1,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				deviceId: $deviceId,
				projectId: $projectId,
	  		hostId: $hostId, 
				orderBy: SCHEDULED_TIME,
				direction: DESC,
				status: [COMPLETED],
				hostIsUp: true,
				scanTypes: $scanTypes,
	    ) {
	      results {
	        id
	        scheduledTime
	        duration
	        hostIsUp
	        rawDeviceId
	        task{
	        	errorMessage
	        }
        	network{
        		id
        		location
        	}
      		organization{
      			id
      			slug
      			name
      		}
	        fileName
	        fileSize
	        vlan {
	        	id
						vid
						name
						type
						accessPolicy
						description
	        }
	        host {
	          id
	          displayName
	        }
	        requestedBy { 
	        	firstname
	        	lastname
	        }
	        portCount
	        device {
	          id
	          isAlive
	          deviceId
	          isRebooting
	          location
	          actions {
	            action
	            enabled
	            disabledReason
	          }
	        }
	        task{
	        	id
		        startTime
		        endTime
		        status	        	
	        }
	        osDetection
	        tcp
	        udp	        
	        hostIpAddr
	        hostMacAddr
	        originalVlanName
	        result {
		        openPorts {
		          protocol
		          port
		          service
		          product
		          version
		          state
		        }
		        closedTcpPorts
						closedUdpPorts
		        osDetection {
		          name
		          accuracy
		          osFamily
		          osGen
		          cpe
		          type
		          vendor
		        }
		      }
	      }
	    }
	  }
	}
`;


export const GET_MANAGEMENT_HOSTPORTS = gql`
  query GET_MANAGEMENT_HOSTPORTS(
    $page: Int
		$itemsPerPage: Int
		$direction: Direction
		$organizationSlug: ShortString
		$networkId: UUID
		$search: ShortString
		$fromDate: DateTime
		$toDate: DateTime
		$mostRecent: Boolean
		$hostId: UUID,
		$protocols: [ProtocolType!]
		$portNumber: Int
		$states: [PortStateEnum!]
		$orderBy: HostPortsOrderBy
  ){    
   	management {
	    hostPorts(
	    	page: $page,
				itemsPerPage: $itemsPerPage,
				direction: $direction,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				search: $search,
				fromDate: $fromDate,
				toDate: $toDate,
				mostRecent: $mostRecent,
				hostId: $hostId,
				protocols: $protocols,
				portNumber: $portNumber,
				states: $states,
				orderBy: $orderBy,
	    ) {
	      pageInfo {
	  			count
	  			itemsPerPage
	  			maxItemsPerPage
	  			currentPage
	  			numPages
	      }
	      results {
	        id
	        host {
	          displayName
	          id
	          podNetwork{
	          	pod{
	          		id
	          	}
	          }
	          lastConnection {
	            vlan {
			        	id
								vid
								name
								type
								accessPolicy
								description
			        }
			        trustedPairingDetails
			        trustedPairingStatus
	            context {
	              vlanName,
	              hostType
	            }
	          }
	        }
	        protocol
	        portNumber
	        scan {
	        	id
	        	network{
	        		id
	        		location
	        	}
	      		organization{
	      			id
	      			slug
	      			name
	      		}
	          task {endTime}
	          hostIpAddr
	          hostMacAddr
	          originalVlanName
	          vlan {
		        	id
							vid
							name
							type
							accessPolicy
							description
		        }
	          osDetection
	          hostIsUp
	          tcp
	          udp
	        }
	        state
	        service
	        product
	        version
	      }
	    }
	  }
	}
`;
import gql from "graphql-tag";

export const GET_MANAGEMENT_VLANACCESS = gql`
  query GET_MANAGEMENT_VLANACCESS(
    $page: Int
		$itemsPerPage: Int
		$online: Boolean
		$authorizationStatus: [AuthorizationStatus]
		$vlanId: UUID
		$orderBy: ManagementVLANAccessOrderBy
		$direction: Direction
  ){
    management{
      vlanAccess(
        page: $page
				itemsPerPage: $itemsPerPage
				online: $online
				authorizationStatus: $authorizationStatus
				vlanId: $vlanId
				orderBy: $orderBy
				direction: $direction
      ){
        pageInfo{
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results{
					id
					ip
					changedByName
					changedOn
					status
          vlan {
            id
            vid
            name
            accessPolicy
            management
          }
					host{
						id
						macAddr
						displayName
						organization {
							name
							slug
							id
						}
	          countOpenPorts
	          countMonitoredPorts
	          device{
	            id
	            deviceId
	            location
	          }
	          hostType
	          podNetwork{
	            id
	            pod{
	              id
	              currentVersion{
	              recipe {
	                id
	                displayName
	                }
	              }
	            }
	          }
	          latestPortScan {
	            id
	            portCount
	            hostIsUp
	          }
						lastConnection {
							isOnline
	          }
	          countPacketLogs
						created
						deviceType
						isBlocked
	          hasPromptAuth
	          authorizations{
	            network{
	              id
	              location
	            }
	            vlans{
	              lastConnection {
	                lastSeen
	                startTime
	                badConnection
	              }
	              status
	              vlan {
	                id
	                vid
	                name
	                accessPolicy
	                management
	              }
	            }
	          }
					}
					lastConnection {
            lastSeen
            startTime
						endTime
						isOnline
						media
						band
            badConnection
						context{
							portLabel
							deviceLocation
							deviceId
						}
						network{
							id
							location
						}
						device{
							ports{
								usage
								portNumber
								label
							}
						}
						ssid{
							id
							name
						}
          }
        }
      }
    }
  }`;

export const GET_MANAGEMENT_VLANACCESS_STATS = gql`
  query GET_MANAGEMENT_VLANACCESS_STATS(
		$vlanId: UUID
  ){
    management{
			countAllHosts: vlanAccess(
				authorizationStatus: [PROMPT, DENY, ALLOW]
				vlanId: $vlanId
      ){
        pageInfo{
          count
        }
      }
      vlanAccessWaitingForPermission: vlanAccess(
				authorizationStatus: PROMPT
				vlanId: $vlanId
      ){
        pageInfo{
          count
        }
      }
      vlanAccessDenied: vlanAccess(
				authorizationStatus: DENY
				vlanId: $vlanId
      ){
        pageInfo{
          count
        }
      }
      vlanAccessAllowed: vlanAccess(
				authorizationStatus: ALLOW
				vlanId: $vlanId
      ){
        pageInfo{
          count
        }
      }
    }
  }`;
import _ from 'lodash';

export function getOrganizationUrls({organizationSlug, origin= "dashboard", id=null, hostId=null, userPane=null, location=null, appliance=null}) {
	const base = `/${origin}/organization/${organizationSlug}`;
	return {
		addLocation: 										`${base}/add-location`,
		addUser: 												`${base}/users/add-user`,
		blockedDevices: 								`${base}/devices?devices-${organizationSlug}-pane=2`,
		connectedDevices: 							`${base}/devices?devices-${organizationSlug}-pane=1` + (location ? `&location=${location}` : '') + (appliance ? `&appliance=${appliance}` : ''),
		devices: 												`${base}/devices`,
		edit: 													`${base}/edit`,
		host: 													`${base}/devices/${hostId}`,
		hostProperties: 								`${base}/devices/${hostId}/properties`,
		main:   												base,
		notifications: 									`${base}/notifications`,
		settings: 											`${base}/settings`,
		settingsUserEdit: 							`${base}/settings/user/edit`,
		settingsUserPassword: 					`${base}/settings/user/password`,
		settingsNotifications: 					`${base}/settings/notifications`,
		unauthorizedDevices: 						`${base}/devices/unauthorized-devices`,
		user: 													`${base}/users/user/${id}`,
		userDevices: 										`${base}/users/user/${id}?user-${id}-pane=${userPane}`,
		userPassword: 									`${base}/users/user/${id}/password`,
		users: 													`${base}/users`,
	};
}

export function getPages(options = {"origin": "dashboard"}) {
	return {
		addOrganization:`/${options.origin}/add-organization`,
		backOffice:`/support`,
		error404:`/${options.origin}/404error`,
		selectOrganization:`/${options.origin}/select-organization`,
	};
}

export function getSupportUrls(_options) {
	var options = Object.assign({
		appliance: null, 
		applianceName: null, 
		bpfFilter: null,
		bssid: null,
		bssidName: null,
		diskId: null,
		diskName: null,
		domainName: null,
		location:null, 
		locationName:null, 
		vlan:null, 
		host: null, 
		hostName: null, 
		incidentId:null,
		macAddr: null, 
		maxDuration: null,
		maxPackets: null,
		organizationSlug:null, 
		organizationSlugName:null, 
		origin:"support", 
		ip: null, 
		packetId: null,
		podId: null,
		port: null,
		portId: null,
		portLabel: null,
		portUsage: null,
		project: null,
		projectName: null,
		recipe: null,
		recipeName: null,
		service: null,
		scheduledTime: null,
		search:null, 
		ssid:null, 
		ssidName:null,
		suffixName: null,
		taskId: null,
		userId:null,
		userName: null, 
		vlanId: null,
		vlanName: null, 
	}, _options || {});

	var origin = 								options.origin && encodeURIComponent(options.origin);
	var ip = 										options.ip && encodeURIComponent(options.ip);
	var organizationSlug = 			options.organizationSlug && encodeURIComponent(options.organizationSlug);
	var organizationSlugName = 	options.organizationSlugName && encodeURIComponent(options.organizationSlugName);
	var location = 							options.location && encodeURIComponent(options.location);
	var locationName = 					options.locationName && encodeURIComponent(options.locationName);
	var vlan = 									options.vlan && encodeURIComponent(options.vlan);
	var appliance = 						options.appliance && encodeURIComponent(options.appliance);
	var applianceName = 				options.applianceName && encodeURIComponent(options.applianceName);
	var domainName = 						options.domainName && encodeURIComponent(options.domainName);
	var suffixName = 						options.suffixName && encodeURIComponent(options.suffixName);
	var incidentId = 						options.incidentId && encodeURIComponent(options.incidentId);
	var macAddr = 							options.macAddr && encodeURIComponent(options.macAddr);
	var host = 									options.host && encodeURIComponent(options.host);
	var hostName = 							options.hostName && encodeURIComponent(options.hostName);
	var project = 							options.project && encodeURIComponent(options.project);
	var projectName = 					options.project && encodeURIComponent(options.projectName);
	var recipe = 								options.recipe && encodeURIComponent(options.recipe);
	var recipeName = 						options.recipe && encodeURIComponent(options.recipeName);
	var service = 							options.service && encodeURIComponent(options.service);
	var userId = 								options.userId && encodeURIComponent(options.userId);
	var podId = 								options.podId && encodeURIComponent(options.podId);
	var taskId = 								options.taskId && encodeURIComponent(options.taskId);
	var userName = 							options.userName && encodeURIComponent(options.userName);
	var search = 								options.search && encodeURIComponent(options.search);
	var zone = 									options.zone && encodeURIComponent(options.zone);
	var bssid = 								options.bssid && encodeURIComponent(options.bssid);
	var bssidName = 						options.bssidName && encodeURIComponent(options.bssidName);
	var diskId = 								options.diskId && encodeURIComponent(options.diskId);
	var diskName = 							options.diskName && encodeURIComponent(options.diskName);
	var maxDuration = 					options.maxDuration && encodeURIComponent(options.maxDuration);
	var maxPackets = 						options.maxPackets && encodeURIComponent(options.maxPackets);
	var packetId = 							options.packetId && encodeURIComponent(options.packetId);
	var vlanId = 								options.vlanId && encodeURIComponent(options.vlanId);
	var vlanName = 							options.vlanName && encodeURIComponent(options.vlanName);
	var bpfFilter = 						options.bpfFilter && encodeURIComponent(options.bpfFilter);
	var scheduledTime = 				options.scheduledTime && encodeURIComponent(options.scheduledTime);
	var ssid = 									options.ssid && encodeURIComponent(options.ssid);
	var ssidName = 							options.ssidName && encodeURIComponent(options.ssidName);
	var port = 									options.port && encodeURIComponent(options.port);
	var portId = 								options.portId && encodeURIComponent(options.portId);
	var portLabel = 						options.portLabel && encodeURIComponent(options.portLabel);
	var portUsage = 						options.portUsage && encodeURIComponent(options.portUsage);

	var filters = !!(search || ip || location || diskId || domainName || suffixName || organizationSlug || ssid || host || port || recipe || userId || project || macAddr || appliance || zone || bssid || podId || vlanId);

	var organizationStr = (organizationSlug ? `&organizationSlug=${organizationSlug}` : '')
												+ (organizationSlugName ? `&organizationSlugName=${organizationSlugName}` : '');

	var locationStr = (location ? `&location=${location}` : '')
										+ (locationName ? `&locationName=${locationName}` : '');

	var ssidStr = (ssid ? `&ssid=${ssid}` : '')
										+ (ssidName ? `&ssidName=${ssidName}` : '');

	var applianceStr = 	(appliance ? `&device=${appliance}` : '')
											+ (applianceName ? `&deviceName=${applianceName}` : '');
	
	var projectStr = 	(project ? `&project=${project}` : '')
											+ (projectName ? `&projectName=${projectName}` : '');
	
	var recipeStr = 	(recipe ? `&recipe=${recipe}` : '')
											+ (recipeName ? `&recipeName=${recipeName}` : '');

	var bssidStr = (bssid ? `&bssid=${bssid}` : '')
							+ (bssidName ? `&bssidName=${bssidName}` : '')

	var diskStr = (diskId ? `&diskId=${diskId}` : '')
							+ (diskName ? `&diskName=${diskName}` : '')

	var ipStr = (ip ? `&ip=${ip}` : '')

	var domainNameStr = (domainName ? `&domainName=${domainName}` : '')
	var suffixNameStr = (suffixName ? `&suffixName=${suffixName}` : '')

	var hostStr = (host ? `&host=${host}` : '')
							+ (hostName ? `&hostName=${hostName}` : '')

	var userStr = (userId ? `&user=${userId}` : '')
							+ (userName ? `&userName=${userName}` : '')

	var vlanStr = (vlanId ? `&vlanId=${vlanId}` : '')
							+ (vlanName ? `&vlanName=${vlanName}` : '')

	var captureStr 	= (maxDuration ? `&maxDuration=${maxDuration}` : '')
									+(maxPackets ? `&maxPackets=${maxPackets}` : '')
									+(vlanId ? `&vlanId=${vlanId}` : '')
									+(vlanName ? `&vlanName=${vlanName}` : '')
									+(bpfFilter ? `&bpfFilter=${bpfFilter}` : '')
									+(scheduledTime ? `&scheduledTime=${scheduledTime}` : '')
									+(portId ? `&portId=${portId}` : '')
									+(portLabel ? `&portLabel=${portLabel}` : '')
									+(portUsage ? `&portUsage=${portUsage}` : '')

	var surveyStr 	= (maxDuration ? `&maxDuration=${maxDuration}` : '')
									+(scheduledTime ? `&scheduledTime=${scheduledTime}` : '')

	const urls = {
		user:`/${origin}/users/${userId}`,
		userPwd:`/${origin}/users/self/password`,
		userAdd:`/${origin}/users/add`,
		userHistory:`/${origin}/users/history${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ userStr,

		users:`/${origin}/users${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr,

		accountSsidAdd:`/${origin}/accounts/${organizationSlug}/ssids/add`,
		account:`/${origin}/accounts/${organizationSlug}${filters ? "?" : ""}`,
		accounts:`/${origin}/accounts${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr,
					
		location:`/${origin}/locations/${location}${filters ? "?" : ""}`,		
		locations:`/${origin}/locations${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr,

		locationDiagram:`/${origin}/locations/${organizationSlug}/${location}/diagram${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : ''),
							
		locationSsidAdd:`/${origin}/locations/${location}/ssids/add`,
		locationSsid:`/${origin}/locations/${location}/ssids/${ssid}`,

		locationVlan:`/${origin}/locations/${location}/zones/${vlan}`,
		locationVlanEdit:`/${origin}/locations/${location}/zones/${vlan}/edit`,
		locationVlanAdd:`/${origin}/locations/${location}/zones/add`,

		appliance:`/${origin}/appliance/${appliance}${filters ? "?" : ""}`,
		applianceAddService:`/${origin}/appliance/${appliance}/add-service${filters ? "?" : ""}`
							+ projectStr,

		appliancePorts:`/${origin}/appliance/${appliance}/edit-ports${filters ? "?" : ""}`,

		appliances:`/${origin}/appliances${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ applianceStr
							+ locationStr,
		disks:`/${origin}/appliances/disks${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ applianceStr
							+ locationStr
							+ applianceStr,

		monitoringEvents:`/${origin}/appliances/monitoring-events${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr,
		
		pod:`/${origin}/appliances/embedded-services/${podId}`,
		embeddedServices:`/${origin}/appliances/embedded-services${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ projectStr
							+ recipeStr
 							+ applianceStr,

		volumes:`/${origin}/appliances/volumes${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
 							+ applianceStr
 							+ diskStr,

		appliancesHistory:`/${origin}/appliances/history${appliance ? `/${appliance}` : ""}/${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr,

		appliancesInventory:`/${origin}/appliances/inventory${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ applianceStr
							+ locationStr,

		appliancesInventoryHistory:`/${origin}/appliances/inventory/history${appliance ? `/${appliance}` : ""}${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr,

		host:`/${origin}/hosts/${host}`,

		hosts:`/${origin}/hosts${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ applianceStr
							+ vlanStr
							+ locationStr,

		portforwards:`/${origin}/hosts/portforwards${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr,

		portForwardHistory:`/${origin}/hosts/portforwards/history${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ locationStr
							+ hostStr
							+ (!!port ? `&port=${port}` : ""),

		connections:`/${origin}/connections${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ ssidStr
							+ vlanStr
							+ (macAddr ? `&macAddr=${macAddr}` : '')
							+ hostStr,

		incidents:`/${origin}/incidents${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr,

		service:`/${origin}/services/${service}`,

		services:`/${origin}/services${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : ''),

		project:`/${origin}/projects/${project}`,

		projects:`/${origin}/projects${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : ''),


		task:`/${origin}/tasks/${taskId}`,
		tasks:`/${origin}/tasks${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ (podId ? `&podId=${podId}` : '')
							+ hostStr,

		surveillance:`/${origin}/surveillance${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr,

		surveillancePacketCaptures:`/${origin}/surveillance/packetcaptures${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr,							
		surveillanceEditcaptureFromTask: `/${origin}/surveillance/editcapture_fromtask/` + packetId,
		surveillanceEditcapture: `/${origin}/surveillance/editcapture/` + packetId + "?"
							+ (zone ? `zone=${zone}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr
							+ captureStr,

		surveillanceAddcapture:`/${origin}/surveillance/addcapture${filters ? "?" : ""}`
							+ (zone ? `&zone=${zone}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr
							+ captureStr,

		surveillanceInterceptions:`/${origin}/surveillance/ip${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ bssidStr
							+ hostStr
							+ ipStr,

		surveillanceDnsSurveillance:`/${origin}/surveillance/dns-surveillance${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ domainNameStr
							+ suffixNameStr
							+ hostStr,

		surveillanceWifiSurveys:`/${origin}/surveillance/wifisurveys${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ bssidStr
							+ hostStr,	

		surveillanceAddWifiSurvey:`/${origin}/surveillance/addwifisurvey${filters ? "?" : ""}`
							+ (zone ? `&zone=${zone}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr
							+ surveyStr,

		surveillancePortScans:`/${origin}/surveillance/portscans${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr,

		surveillancePorts:`/${origin}/surveillance/ports${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr,	
							
		surveillanceAddPortScan:`/${origin}/surveillance/addportscan${filters ? "?" : ""}`
							+ (zone ? `&zone=${zone}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ hostStr
							+ surveyStr,

		surveillanceRogueWifis:`/${origin}/surveillance/roguewifis${filters ? "?" : ""}`
							+ (zone ? `&zone=${zone}` : '')
							+ organizationStr
							+ locationStr
							+ applianceStr
							+ surveyStr,
							
		statistics:`/${origin}/statistics/`,
		statsDns:`/${origin}/statsdns/${filters ? "?" : ""}`
							+ (search ? `&search=${search}` : ''),
		incident:`/${origin}/incidents/${incidentId}`,
		error404:`/${origin}/404error${filters ? "?" : ""}`,
		main:`/${origin}`
	};

	for(let key in urls){
		urls[key] = urls[key].replace("?&", "?")
	}

	return urls;
}

export function getLocationUrls({organizationSlug,location, origin= "dashboard"}) {
	const base = `/${origin}/organization/${organizationSlug}/location/${location}`;
	return {
		activity: 				`${base}/activity`,
		addAccessPoint: 	`${base}/addAccessPoint`,
		addDrone: 				`${base}/addDrone`,
		addGuestWifi: 		`${base}/add-guestwifi`,
		addPortal: 				`${base}/add-portal`,
		addPskNetwork: 		`${base}/add-pskwifi`,
		addRouter: 				`${base}/addRouter`,
		addVlan: 					`${base}/add-vlan`,
		appliances: 			`${base}/appliances`,
		diagram:					`${base}/diagram`,
		incidents: 				`${base}/incidents`,
		main: 						base,
		manageVlans: 			`${base}/manage-vlans`,
		rogues: 					`${base}/wifis/rogues`,
		selectWifiToAdd: 	`${base}/selectWifiToAdd`,
		settings: 				`${base}/settings`,
		vlans: 						`${base}/vlans`,
		wifis: 						`${base}/wifis`,
		wifisOffline: 		`${base}/wifis?location-pane=1`,
		wifisPartial: 		`${base}/wifis?location-pane=2`,
	};
}

export function getDocUrls() {
	const root = `https://docs.${window.sentinelc_domain}`;
	const base = `https://docs.${window.sentinelc_domain}/docs/user-guides`;
	return {
		backOffice:{
			main: 										`${root}`,
			docs: 										`${root}/docs`,
			faq: 											`${root}/docs/faq`,
			releaseNotes:							`${root}/docs/release-notes`,
			technicalGuides:					`${root}/docs/technical-guides`,
			base: 										`${base}`,
			accounts: 								`${base}/administration/accounts/overview/`,
			admins: 									`${base}/administration/manage-administrators/`,
			adminsCreate: 						`${base}/administration/manage-administrators/#create-a-network-administrator`,
			advancedPortConfig:				`https://docs.${window.sentinelc_domain}/docs/technical-guides/advanced-port-config/intro/`,
			trustedPairing:  					`https://docs.${window.sentinelc_domain}/docs/technical-guides/trusted-pairing/`,
			appliances: 							`${base}/administration/appliances/overview/`,
			appliancesClaim: 					`${base}/administration/appliances/operations/#claim`,
			appliancesManage: 				`${base}/administration/appliances/operations/`,
			appliancesInventory: 			`${base}/administration/appliances/manage-inventory/`,
			applianceDisks: 					`${base}/administration/appliances/disks/`,
			applianceDisksPartitions:	`${base}/administration/appliances/disks-and-partitions`,
			appliancePorts: 					`${base}/administration/appliances/network-ports/#edit-ports`,
			applianceQos: 						`https://docs.${window.sentinelc_domain}/docs/technical-guides/qos/`,
			applianceServices: 				`${base}/administration/appliances/embedded-services/`,
			applianceWifi: 						`${base}/administration/appliances/wi-fi-networks/#edit-wi-fi`,
			connections: 							`${base}/administration/manage-connections/`,
			dns: 											`${base}/administration/surveillance/dns/`,
			hosts: 										`${base}/administration/manage-devices/overview`,
			portForwarding: 					`${base}/administration/manage-devices/port-forwarding/`,
			incidents: 								`${base}/administration/manage-incidents/`,
			interceptions:						`${base}/administration/surveillance/ip-interceptions/`,
			locations: 								`${base}/administration/locations/overview/`,
			locationImport: 					`${base}/administration/accounts/locations#import-a-location`,
			locationExport: 					`${base}/administration/locations/overview#export-configuration`,
			zones: 										`${base}/administration/locations/zone-configuration/`,
			monitoringEvents:					`${base}/administration/appliances/monitoring-events/`,
			services: 								`${base}/administration/service-library/`,
			packetCaptures: 					`${base}/administration/surveillance/packet-captures/`,
			packetCapturesAdd: 				`${base}/administration/surveillance/packet-captures/#how-to-launch-a-packet-capture`,
			projects: 								`${base}/administration/manage-projects/`,
			projectsEdit: 						`${base}/administration/projects/#how-to-perform-different-operations-on-a-project`,
			portScans: 								`${base}/administration/surveillance/port-scans/`,
			portSurveillance:					`${base}/administration/surveillance/ports-under-surveillance`,
			portScansAdd: 						`${base}/administration/surveillance/port-scans/#perform-a-port-scan-on-a-device-connected-to-the-sentinelc-network`,
			tasks: 										`${base}/administration/manage-tasks/`,
			volumes:    		      		`${base}/administration/appliances/volumes-on-disks`, 
			vpn: 											`${root}/docs/technical-guides/vpn/`,
			wifiScans: 								`${base}/administration/surveillance/wi-fi-scans/`,
			wifiScansAdd: 						`${base}/administration/surveillance/wi-fi-scans/#start-new-wi-fi-scan`,
			wifiScansRogue: 					`${base}/administration/surveillance/potential-rogue-wi-fi/`,
		},
		dashboard: {
			addAccessPoint: `${base}/application/appliance/main-function/#add-an-access-point`,
			addDrone: 			`${base}/application/appliance/main-function/#add-a-drone`,
			addRouter: 			`${base}/application/appliance/main-function/#add-appliance`,
			addLocation: 		`${base}/application/manage-location/`,
			addUser: 				`${base}/application/manage-my-profile/`,
			addWifi: 				`${base}/application/manage-network/`,
			devices: 				`${base}/application/manage-client-device/`,
			notifications:	`${base}/application/notifications/`,
			replaceRouter: 	`${base}/application/replace-failing-appliance/`,
			settings: 			`${base}/application/manage-my-profile/#settings`,
			zones: 					`${base}/application/manage-zone/`,
		}
	};
}

export function getNetworkUrls({organizationSlug,location,vlanId, networkSlug, origin= "dashboard"}) {
	const base = `/${origin}/organization/${organizationSlug}/location/${location}/vlan/${vlanId}`
	return {
		main: base,
		activity: `${base}/network/${networkSlug}/activity`,
		password: `${base}/network/${networkSlug}/password`,
		schedule: `${base}/network/${networkSlug}/schedule`,
		settings: `${base}/network/${networkSlug}/settings`,
	};
}

export function getDeviceUrls({organizationSlug,location,deviceId, origin= "dashboard"}) {
	return {
		main: 		`/${origin}/organization/${organizationSlug}/location/${location}/device/${deviceId}`,
		replace: 	`/${origin}/organization/${organizationSlug}/location/${location}/device/${deviceId}/replace`,
	};
}

export function getVlanUrls({organizationSlug,location,vlanId, origin= "dashboard"}) {
	const base = `/${origin}/organization/${organizationSlug}/location/${location}/vlan/${vlanId}`;
	return {
		addAccessPoint: 	`${base}/addAccessPoint`,
		addPortal: 				`${base}/add-portal`,
		addGuestWifi: 		`${base}/add-guestwifi`,
		addPskNetwork: 		`${base}/add-pskwifi`,
		edit: 						`${base}/edit`,
		main: 						base,
		selectWifiToAdd: 	`${base}/selectWifiToAdd`,
	};
}

export function getUserUrl({id, origin= "dashboard"}) {
	return `/${origin}/user/${id}`;
}

export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      if(decodeURIComponent(pair[0]) !== "") query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function decodeFilterOnQuery(filters){
	if(!filters) return null;
	let arrayFilters = decodeURI(filters).split(",");
	let decodedFilters= {};
	for (let i = 	arrayFilters.length - 1; i >= 0; i--) {
		const roughtValue = arrayFilters[i].split(":");
		decodedFilters[roughtValue[0]] = roughtValue[1].split("|");
	}
	return decodedFilters;
}

export function encodeFilterOnQuery(filters){
	/* filters: based on variables model : {key: value|array} */
	if(!filters) return "";
	let encodedFilters= [];
	let isFirstFilter = true;
	for(let key in filters){
		if(filters[key] === null || _.get(filters[key],"length") === 0) continue;
		let singleFilter = [`${isFirstFilter ? "": ","}${key}:`];
		// Set values
		if(_.get(filters[key],"length") > 0 && typeof filters[key] !== "string"){
			filters[key].forEach((value, idx) => singleFilter.push(`${idx === 0 ? "":"|"}${value}`));
		} else if(filters[key] !== null){
			singleFilter.push(""+filters[key]);
		} else {
			continue;
		}
		encodedFilters.push(singleFilter.join(""));
	}
	return encodeURI(encodedFilters.join(","));
}

export function replaceOnQuery(queryString, pairsToChange) {
  var query = Object.assign(parseQuery(queryString), pairsToChange);
  var newQueryString = [];

  for (var i in query) {
  	if (query[i]) newQueryString.push(`${i}=${encodeURIComponent(query[i])}`);
  }

  if(newQueryString.length === 0) return "";
  return "?" + newQueryString.join('&');
}

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form, List } from 'semantic-ui-react';

import store from 'tools/SimpleStore.js';

const options = [
  { key: 'fr', text: 'Français', value: 'fr' },
  { key: 'en', text: 'English', value: 'en' },
]


export class LanguageSelectorBase extends Component{

  constructor(props){
    super(props);

    this.state = {
      language: store('locale'),
      actions: {
        onChangeLanguage: (e, target) => {
          store('locale', target.value);
          window.location.reload()
        },
      }
    }
  }

  render(){

    const{
      actions,
      language,
    } = this.state;

    const{
      intl,
      list,
      code = null,
    } = this.props;

    if(list) return (
      <List className="languageselector">
        {options.map((lang, key) => {
          return language === lang.value ? null : <List.Item role="button" key={key} onClick={() => actions.onChangeLanguage(null,lang)}>{code ? lang.value.toUpperCase() : lang.text}</List.Item>
        })}
      </List>
    );

    return(
      <Form>
        <Form.Group widths='equal'>
          <Form.Select
            id="language"
            fluid
            label={!list && intl.formatMessage({id:'forms.generic.selectLanguage'})}
            options={options}
            value={language}
            onChange={actions.onChangeLanguage}
          />
        </Form.Group>
      </Form>
    );
  }
}

export const LanguageSelector = injectIntl(LanguageSelectorBase);
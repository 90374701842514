import React, { Component } from 'react';
import _ from 'lodash';

import { ManagementController } from 'controllers/management/ManagementController';
import { PreferencesController } from 'controllers/management/Preferences/PreferencesController';

const DEFAULT_PROPS = {
  render: () => {},
  callback: () => {},
  viewer: {},
}

export class UserPrefsProviderBase extends Component{

	constructor(props){
		super(props);

		this.state = {userUIPrefs: {
        key: "userUIPrefs",
        scope: "USER",
        value: {
          version: 0, // Be careful if you upgrade, use 0.1 increment, changing the major version (before the dot) will erase data
          prefs: {
            "advancedModeUi" : true,
            "darkMode" : false,
            "homeLanding" : 0,
          },
        },
      }
    }
	}

  changeSingleField(name= "", value = null, onSubmit = () => {}){
    const {
      userUIPrefs,
    } = this.state;

    let data = _.cloneDeep(userUIPrefs);
    if(typeof data.value === "string") data.value = JSON.parse(data.value);

    if(_.get(data,`value.prefs`)){
      data.value.prefs[name] = value;
      data.value = JSON.stringify(data.value);
      onSubmit(data);
    }
  }

	render(){

    const {
      callback,
      onCompleted,
      render, 
      viewer,
    } = this.props;

		return(		      
      <ManagementController
        variables={{
          key: "userUIPrefs",
          scope: "USER",
          userId:_.get(viewer,"id"),
        }}
        operation="preferences"
        onCompleted={(data) => {
        	const {
        		userUIPrefs,
        	} = this.state;

          let newUserUIPrefs = Object.assign(userUIPrefs,_.get(data,"uiPreference"));
          if(typeof _.get(newUserUIPrefs,"value") === "string") newUserUIPrefs.value = JSON.parse(newUserUIPrefs.value);
          if(newUserUIPrefs.value){
            // Exact same version : use DB data
            if(_.get(newUserUIPrefs,"value.version") === _.get(this.state.userUIPrefs,"value.version")){
              userUIPrefs.value = _.get(newUserUIPrefs,"value");
              this.setState({userUIPrefs});

            } 
            // Same major version, upgrade schema with this file model, using available DB values
            else if(Math.floor(_.get(userUIPrefs,"value.version")) === Math.floor(_.get(this.state.userUIPrefs,"value.version"))){
              userUIPrefs.value = Object.assign(userUIPrefs.value, _.get(newUserUIPrefs,"value"));
              this.setState({userUIPrefs});
            }            
          }
          // Other cases : DB schema is not used and will be replaced by the new model, removing data
          _.get(userUIPrefs,"value.prefs.darkMode") ? document.body.classList.add("darkmode") : document.body.classList.remove("darkmode");
          if(typeof onCompleted === "function") onCompleted(data, userUIPrefs);
        }}
        render={prefsPropsController =>
        	<PreferencesController
			      operation={"update"}
			      onCompleted={(data) => {
			      	prefsPropsController.refetch();
              if(typeof callback === "function") callback(data);
			      }}
			      render={(preferencesUpdateProps) => {
              const propsToRender = {
                ...preferencesUpdateProps, 
                data: this.state.userUIPrefs, 
                changeField: (name, value) => this.changeSingleField(name, value, preferencesUpdateProps.onSubmit)
              };
              if (typeof render === "function") return render(propsToRender);
              return React.cloneElement(this.props.children, propsToRender);
            }}
					/>
        }
      />
		)
	}
}

export function UserPrefsProvider(props){
  const componentProps = Object.assign({}, DEFAULT_PROPS, props);
  return <UserPrefsProviderBase {...componentProps} />
}
